import React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image' 
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
import { useWindowSize } from "../components/general/window";
import styled from "styled-components"
import Layout from "../components/layout/layout"
import Head from '../components/general/head'
import { PageContainer, ContentWrapper, DeepBackground, HeadingWrapper, Title, Line, SubTitle, SolidBackground, 
         TextureBackground, Container, MediaContainer, InfoBox, BigNumber, Top, Bottom, MediaText, MediaTitle, Description, BumperOffset, Bumper } from '../components/general/general'
import { HelpButton } from "../components/general/helpButton.js"
import Video from "../components/general/video"

import "@fontsource/raleway/900.css"
import "@fontsource/raleway/800.css"
import "@fontsource/raleway/700.css"
import "@fontsource/raleway/600.css"
import "@fontsource/raleway/500.css"
import "@fontsource/raleway/400.css"
import "@fontsource/work-sans/700.css"

const TopAlt = styled(Top)`
    font-size: 1rem;
`;

const DiscussionsPage = (props) => {

    let window = useWindowSize()
    const width  = window[0]

    return (
            <Layout>
                <Head title="Discussions"/>
            <PageContainer>
                <ContentWrapper>
                <ParallaxProvider>
                    <Parallax
                        y={[-50, 50]}
                        slowerScrollRate
                        disabled={width <= 1024 }
                    >
                        <DeepBackground />
                    </Parallax>
                    
                    <Parallax
                        y={[100, 0]}
                        fasterScrollRate
                        disabled={width <= 1024 }
                    >
                    <HeadingWrapper>
                        <Title>Discussions</Title>
                        <Line>
                            <StaticImage
                                src="../images/lines/two-lines.png"
                                alt="Two Curved Light Weight Line"
                                layout="constrained"
                                // css={`
                                //     width: 65%;
                                //     opacity: .8;
                                // `}
                            />
                        </Line>
                        <SubTitle>The Therapist Team</SubTitle>
                    </HeadingWrapper> 
                    </Parallax>
                    <Parallax disabled={width <= 1024 }>
                        <SolidBackground>
                            <TextureBackground>
                                <Bumper>
                                    <HelpButton />
                                </Bumper>
                                <BumperOffset>
                                    <Container>
                                        {props.data.contentfulDiscussions.discussions.map((discussion) => {
                                            return (
                                                <MediaContainer id={discussion.discussionId} background="#1F2239">
                                                    {/* <Thumbnail
                                                        image={discussion.discussionThumbnail.gatsbyImageData}
                                                        alt={discussion.discussionThumbnail.description}
                                                    /> */}
                                                    <Video 
                                                        videoSrcURL={discussion.videoSrcUrl.videoSrcUrl}
                                                        videoTitle={discussion.videoTitle}
                                                    />
                                                    <script src="https://player.vimeo.com/api/player.js"></script>
                                                    <InfoBox color="#FFF">
                                                        <BigNumber color="#FFF">
                                                            <TopAlt>
                                                                DISCUSSION
                                                            </TopAlt>
                                                            <Bottom>
                                                                #{discussion.discussionNumber}
                                                            </Bottom>
                                                        </BigNumber>
                                                        <MediaText>
                                                            <MediaTitle>
                                                                {discussion.discussionTitle}
                                                            </MediaTitle>
                                                            {/* <Description>
                                                                {discussion.discussionDescription}
                                                            </Description> */}
                                                        </MediaText>
                                                    </InfoBox>
                                                </MediaContainer>
                                            )
                                        })}
                                    </Container>
                                </BumperOffset>
                            </TextureBackground>
                        </SolidBackground>
                    </Parallax>
                </ParallaxProvider>
                </ContentWrapper>
            </PageContainer>
        </Layout>
    )
}

export const query = graphql`
    query {
        contentfulDiscussions {
            discussions {
                discussionThumbnail {
                    gatsbyImageData (
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                    )
                    description
                }
                discussionId
                discussionNumber
                discussionNumberText
                discussionTitle
                discussionAuthors
                discussionDescription
                videoTitle
                videoSrcUrl {
                    videoSrcUrl
                }
            }
        }
    }
`

export default DiscussionsPage